import * as React from "react"
import Cat from "../images/cat.jpg"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <img src={ Cat } alt="cat"/>
    </main>
  )
}

export default NotFoundPage
